export class Paths {
  static WelcomePage = "/"

  static RulesPage = "/rules"

  static GuidesPage = "/guides"
  static GuidesBeginnersPage = "/guides/beginners"
  static GuidesMapAndMarkersPage = "/guides/mapAndMarkers"
  static GuidesTradersPage = "/guides/traders"
  static GuidesVehiclesPage = "/guides/vehicles"
  static GuidesHelicopterPage = "/guides/helicopter"
  static GuidesReputationPage = "/guides/reputation"
  static GuidesBotsPage = "/guides/bots"
  static GuidesMakingMoneyPage = "/guides/makingMoney"
  static GuidesHuntingPage = "/guides/hunting"
  static GuidesLeatherTanningPage = "/guides/leatherTanning"
  static GuidesFarmingPage = "/guides/farming"
  static GuidesMetalsForgingPage = "/guides/metals"
  static GuidesDirectConnectionPage = "/guides/directConnection"
  static GuidesBulletCalibers = "/guides/bulletCalibers"

  static ItemsDonatePage = "/items-donate"

  static PlayerAreaPage = "/player-area"
  static PlayerAreaRepairBagPage = "/player-area/repair-bag"
  static PlayerTeleportPage = "/player-area/teleport"

  static AdminAreaPage = "/admin-area-page"
  static AdminAreaRebootServerPage = "/admin-area-page/reboot-server"
  static AdminAreaSetRunningValues = "/admin-area-page/set-running-values"
  static AdminAreaInGamePlayerDetailsPage = "/admin-area-page/in-game-player-details"
  static AdminAreaMapViewPage = "/admin-area-page/map-view"

  static LoginSteamPage = "/steam/login"
  static LoginSteamReturnPage = "/steam/login-return"
}